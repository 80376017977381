import React, { useState } from 'react';
import Table, { TableHeader, TableRow, TableCell, TableBody } from './Table';
import './ComparisonTable.css';
import Tooltip from '../../../../../components/CustomToolTip/CustomToolTip';
import getFeatures from './features';
function ComparisonTable({ flags }: any) {
  const [expandedRows, setExpandedRows] = useState([]);
  const isFlagOn = !flags['new-pricing-hide-4-column'];

  type Feature = {
    name: string;
    tooltip?: string;
    free: string | boolean;
    starter: string | boolean;
    business: string | boolean;
    enterprise: string | boolean;
    expandable?: boolean;
    subItems?: Feature[];
  };

  type FeatureSection = {
    category: string;
    items: Feature[];
  };

  const features: FeatureSection[] = getFeatures();
  const toggleRow = (rowName: any) => {
    setExpandedRows((prev) =>
      prev.includes(rowName) ? prev.filter((name) => name !== rowName) : [...prev, rowName],
    );
  };

  const renderValue = (value: any) => {
    if (typeof value === 'boolean') {
      return value ? (
        <span className="comparison-table__icon comparison-table__icon--check">
          <img src="/assets/images/check-blue-icon.svg" />
        </span>
      ) : (
        <span className="comparison-table__icon comparison-table__icon--minus">
          <img src="/assets/images/dash-icon.svg" />
        </span>
      );
    }
    return value;
  };

  return (
    <div className="comparison-table">
      <h2 className="comparison-table__title">Compare Plans</h2>
      <div className="comparison-table__wrapper">
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell isHeader>Features</TableCell>
              <TableCell isHeader>Free</TableCell>
              <TableCell isHeader>Starter</TableCell>
              <TableCell isHeader>Business</TableCell>
              {isFlagOn && <TableCell isHeader>Enterprise</TableCell>}
            </TableRow>
          </TableHeader>
          <TableBody>
            {features.map((section, sectionIndex) => (
              <React.Fragment key={sectionIndex}>
                <TableRow>
                  <TableCell colSpan={5} className="comparison-table__category">
                    {section.category}
                  </TableCell>
                </TableRow>
                {section.items.map((feature, featureIndex) => (
                  <React.Fragment key={featureIndex}>
                    <TableRow
                      className={`comparison-table__row ${
                        feature.expandable ? 'comparison-table__row--expandable' : ''
                      }`}
                      onClick={() => feature.expandable && toggleRow(feature.name)}
                    >
                      <TableCell>
                        <div className="comparison-table__feature">
                          {feature.tooltip ? (
                            <Tooltip
                              element={feature.tooltip}
                              style={{ width: 200 }}
                              tooltipContainerStyle={
                                feature.tooltip
                                  ? { textDecoration: 'underline dotted #555', cursor: 'help' }
                                  : {}
                              }
                            >
                              {feature.name}
                            </Tooltip>
                          ) : (
                            feature.name
                          )}
                          {feature.expandable && (
                            <span className="comparison-table__toggle">
                              {expandedRows.includes(feature.name) ? (
                                <img
                                  src="/assets/images/collapse-icon.svg"
                                  style={{ transform: 'rotate(180deg)' }}
                                />
                              ) : (
                                <img src="/assets/images/collapse-icon.svg" />
                              )}
                            </span>
                          )}
                        </div>
                      </TableCell>
                      <TableCell>{renderValue(feature.free)}</TableCell>
                      <TableCell>{renderValue(feature.starter)}</TableCell>
                      <TableCell>{renderValue(feature.business)}</TableCell>
                      {isFlagOn && <TableCell>{renderValue(feature.enterprise)}</TableCell>}
                    </TableRow>
                    {feature.expandable &&
                      expandedRows.includes(feature.name) &&
                      feature.subItems?.map((subItem, subItemIndex) => (
                        <TableRow key={subItemIndex} className="comparison-table__sub-row">
                          <TableCell>
                            <div className="comparison-table__sub-feature">
                              {subItem.tooltip ? (
                                <Tooltip
                                  element={subItem.tooltip}
                                  style={{ width: 200 }}
                                  tooltipContainerStyle={
                                    feature.tooltip
                                      ? { textDecoration: 'underline dotted #555', cursor: 'help' }
                                      : {}
                                  }
                                >
                                  {subItem.name}
                                </Tooltip>
                              ) : (
                                subItem.name
                              )}
                            </div>
                          </TableCell>
                          <TableCell>{renderValue(subItem.free)}</TableCell>
                          <TableCell>{renderValue(subItem.starter)}</TableCell>
                          <TableCell>{renderValue(subItem.business)}</TableCell>
                          {isFlagOn && <TableCell>{renderValue(subItem.enterprise)}</TableCell>}
                        </TableRow>
                      ))}
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

export default ComparisonTable;
