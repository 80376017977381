import React from 'react';

function Table({ children }: any) {
  return <table className="table">{children}</table>;
}

export function TableHeader({ children }: any) {
  return <thead className="table__header">{children}</thead>;
}

export function TableRow({ children, className = '', ...props }: any) {
  return (
    <tr className={`table__row ${className}`} {...props}>
      {children}
    </tr>
  );
}

export function TableCell({ children, isHeader = false, ...props }: any) {
  const Tag = isHeader ? 'th' : 'td';
  return (
    <Tag className={`table__cell ${isHeader ? 'table__cell--header' : ''}`} {...props}>
      {children}
    </Tag>
  );
}

export function TableBody({ children }: any) {
  return <tbody className="table__body">{children}</tbody>;
}

export default Table;
