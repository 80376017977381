import { useIntl } from 'react-intl';

export const getPlanPermissions = ({ planId, isYearly }: { planId: string; isYearly: boolean }) => {
  const intl = useIntl();
  const plans: any = {
    '0': [
      {
        name: intl.formatMessage({ id: 'billing.features.kasprChromeExtension' }),
        tooltip: intl.formatMessage({ id: 'billing.features.kasprChromeExtension.tooltip' }),
      },
      {
        name: intl.formatMessage({ id: 'billing.features.leadManagement' }),
        tooltip: intl.formatMessage({ id: 'billing.features.leadManagement.tooltip' }),
      },
      {
        name: intl.formatMessage({ id: 'billing.features.enrichmentAutomations' }),
        tooltip: intl.formatMessage({ id: 'billing.features.enrichmentAutomations.tooltip' }),
      },
      {
        name: intl.formatMessage({ id: 'billing.features.integrations' }),
        tooltip: intl.formatMessage({ id: 'billing.features.integrations.tooltip' }),
      },
      {
        name: intl.formatMessage({ id: 'billing.features.CSVEnrichment' }),
        tooltip: intl.formatMessage({ id: 'billing.features.CSVEnrichment.tooltip' }),
      },
    ],
    plan_1: [
      {
        name: intl.formatMessage({ id: 'billing.features.kasprOnSalesNavigator' }),
        tooltip: intl.formatMessage({ id: 'billing.features.kasprOnSalesNavigator.tooltip' }),
      },
      {
        name: intl.formatMessage({ id: 'billing.features.SharedCredits' }),
        tooltip: intl.formatMessage({ id: 'billing.features.SharedCredits.tooltip' }),
      },
      {
        name: isYearly
          ? intl.formatMessage({ id: 'billing.features.upToXExports' }, { value: '3,000' })
          : intl.formatMessage({ id: 'billing.features.upToXExportsPerMonth' }, { value: '250' }),
        tooltip: isYearly
          ? intl.formatMessage({ id: 'billing.features.upToXExports.tooltip' }, { value: '3,000' })
          : intl.formatMessage(
              { id: 'billing.features.upToXExportsPerMonth.tooltip' },
              { value: '250' },
            ),
      },
      {
        name: intl.formatMessage({ id: 'billing.features.LimitedApiAccess' }),
        tooltip: intl.formatMessage({ id: 'billing.features.LimitedApiAccess.tooltip' }),
      },
      {
        name: intl.formatMessage({ id: 'billing.features.teamUsageReports' }),
        tooltip: intl.formatMessage({ id: 'billing.features.teamUsageReports.tooltip' }),
      },
    ],
    plan_2: [
      {
        name: intl.formatMessage({ id: 'billing.features.kasprOnLinkedInRecruiterLite' }),
        tooltip: intl.formatMessage({
          id: 'billing.features.kasprOnLinkedInRecruiterLite.tooltip',
        }),
      },
      ,
      {
        name: isYearly
          ? intl.formatMessage({ id: 'billing.features.upToXExports' }, { value: '12,000 ' })
          : intl.formatMessage(
              { id: 'billing.features.upToXExportsPerMonth' },
              { value: isYearly ? '12,000 ' : '1,000' },
            ),
        tooltip: isYearly
          ? intl.formatMessage(
              { id: 'billing.features.upToXExports.tooltip' },
              { value: '12,000 ' },
            )
          : intl.formatMessage(
              { id: 'billing.features.upToXExportsPerMonth.tooltip' },
              { value: '1,000' },
            ),
      },
      {
        name: intl.formatMessage({ id: 'billing.features.apiAccess' }),
        tooltip: intl.formatMessage({ id: 'billing.features.apiAccess.tooltip' }),
      },
      {
        name: intl.formatMessage({ id: 'billing.features.customMemberPermissions' }),
        tooltip: intl.formatMessage({ id: 'billing.features.customMemberPermissions.tooltip' }),
      },
      {
        name: intl.formatMessage({ id: 'billing.features.exportingOfUsageReports' }),
        tooltip: null,
      },
    ],
    plan_3: [
      {
        name: intl.formatMessage({ id: 'billing.features.unlimitedB2BEmailCredits' }),
        // tooltip: intl.formatMessage({ id: 'billing.features.unlimitedB2BEmailCredits.tooltip' }),
      },
      {
        name: intl.formatMessage({ id: 'billing.features.unlimitedPhoneCredits' }),
        // tooltip: intl.formatMessage({ id: 'billing.features.unlimitedPhoneCredits.tooltip' }),
      },
      {
        name: intl.formatMessage({ id: 'billing.features.intentData' }),
        // tooltip: intl.formatMessage({ id: 'billing.features.intentData.tooltip' }),
      },
      {
        name: intl.formatMessage({ id: 'billing.features.advancedSalesForceEnrichement' }),
        // tooltip: intl.formatMessage({
        //   id: 'billing.features.advancedSalesForceEnrichement.tooltip',
        // }),
      },
      {
        name: intl.formatMessage({ id: 'billing.features.enterpriseLevelCompliance' }),
        // tooltip: intl.formatMessage({ id: 'billing.features.enterpriseLevelCompliance.tooltip' }),
      },
      {
        name: intl.formatMessage({ id: 'billing.features.signleSignOn' }),
        // tooltip: intl.formatMessage({ id: 'billing.features.signleSignOn.tooltip' }),
      },
    ],
  };
  return plans[planId];
};
