import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import './PlansHeader.css';
import { Animation, Switch } from '../../../../components';
import { CURRENCY_MAP } from '../../../../constants';

import CustomToolTip from '../../../../components/CustomToolTip/CustomToolTip';

export function PlansHeaderOld({
  selectPlanDuration,
  subscriptionType,
  plan,
  organizationCredits,
}: any) {
  return (
    <div
      id={'plan_header'}
      className={'plan_section'}
      style={
        subscriptionType === 'yearly' && plan && plan.planId == 'plan_3'
          ? { pointerEvents: 'none' }
          : {}
      }
    >
      <div className={'subscription-tabs'} style={{ margin: 0 }}>
        <ul className="nav nav-tabs" style={{ width: 'max-content' }}>
          <li onClick={() => selectPlanDuration('yearly')}>
            <a className={subscriptionType == 'yearly' ? 'active' : ''}>
              <FormattedMessage
                id={'billing.billAnnually'}
                values={{
                  span: (chunk) => <span> {chunk} </span>,
                }}
              />
            </a>
          </li>
          {organizationCredits.plan.numberOfMonths === 12 ? (
            <CustomToolTip
              style={{
                maxWidth: '20vw',
              }}
              element={
                <FormattedMessage id={'billing.customizePlan.youShouldUnsubscribeTooltip'} />
              }
            >
              <li
                onClick={() => {
                  if (organizationCredits.plan.numberOfMonths !== 12) {
                    selectPlanDuration('monthly');
                  }
                }}
              >
                <a
                  className={subscriptionType == 'monthly' ? 'active' : ''}
                  style={{ cursor: 'not-allowed' }}
                >
                  <FormattedMessage id={'billing.billMonthly'} />
                </a>
              </li>
            </CustomToolTip>
          ) : (
            <li
              onClick={() => {
                if (organizationCredits.plan.numberOfMonths !== 12) {
                  selectPlanDuration('monthly');
                }
              }}
            >
              <a className={subscriptionType == 'monthly' ? 'active' : ''}>
                <FormattedMessage id={'billing.billMonthly'} />
              </a>
            </li>
          )}
        </ul>
      </div>
      {/*<CurrencySelect />*/}
    </div>
  );
}

function CurrencySelect() {
  const [toggle, setToggle] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState('EUR');
  const currencyNames: any = {
    EUR: 'EUR',
    USD: 'USD',
    GBP: 'GBP',
  };
  const handleSelectCurrency = (currency: any) => {
    setSelectedCurrency(currency);
    setToggle(false);
  };
  return (
    <div className={'dropdown currency-select'}>
      <button className="dropdown-toggle currency-label button" onClick={() => setToggle(true)}>
        <span>{CURRENCY_MAP[selectedCurrency]} </span> {currencyNames[selectedCurrency]}
      </button>

      <Animation.SlideDown
        className={'dropdown-menu ' + (toggle ? 'show' : '')}
        toggle={toggle}
        setToggle={setToggle}
        style={{ minWidth: '95%', marginLeft: -2, padding: 0 }}
      >
        <div style={{ fontSize: 13, padding: '5px 0' }}>
          <div onClick={() => handleSelectCurrency('EUR')} className={'currency-label line'}>
            <span>{CURRENCY_MAP['EUR']} </span> EUR
          </div>

          <div onClick={() => handleSelectCurrency('USD')} className={'currency-label line'}>
            <span>{CURRENCY_MAP['USD']}</span> USD
          </div>

          <div onClick={() => handleSelectCurrency('GBP')} className={'currency-label line'}>
            <span>{CURRENCY_MAP['GBP']}</span> GBP
          </div>
        </div>
      </Animation.SlideDown>
    </div>
  );
}
