import { FormattedMessage, useIntl } from 'react-intl';
import './PlanCard.css';
import usePlans from '../../Plans/plans.hooks';
import PlanPermissions from '../PlanPermissions/PlanPermissions';

export default function StaticPlanCard({}: any) {
  return (
    <div id={'plan-card'}>
      <div className={'activate-plan'}>
        <div className={'plan-card-header'}>
          {/* PLAN NAME */}

          <div
            style={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <h2 className={'plan-card-title'}>Enterprise</h2>
          </div>
          {/* PLAN PRICE INFOS */}

          <div className="text-price">
            <div style={{ display: 'flex', alignItems: 'end' }}>
              <span className={'price'}>
                <FormattedMessage id={'billing.customPrice'} />
              </span>
            </div>
            <p className="font-sm">
              <FormattedMessage id="billing.forBiggerTeam" />
            </p>
          </div>

          {/* CREDITS */}

          <div className="credits-section">
            <div className="credits-section-title">
              <h3>
                {' '}
                <FormattedMessage id="billing.needMore?" />
              </h3>
            </div>
          </div>

          {/* CREDITS SETTINGS */}

          <div className={'select-credits-container'}>
            <div className={'select-credits'}>
              <p>
                <FormattedMessage id={'billing.getATailoredSolution'} />
              </p>
            </div>
          </div>
        </div>

        <div className={'plan-card-content'}>
          {/* CURRENT PLAN / CHOOSE A PLAN */}

          <div className="cta-button-container">
            <a
              className="plan-btn current first-button"
              href="https://www.kaspr.io/talk-to-sales?hsCtaTracking=c0e55300-e0b6-4873-a368-3d4dc8281136%7C13f9a8a8-76c8-44aa-a6eb-af192c438c69"
              target="_blank"
              style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
            >
              <FormattedMessage id={'billing.talkToSales'} />
            </a>
          </div>
          {/* STATIC PLAN */}

          <div id="static-plan">
            <PlanPermissions currentPlan={{ planId: 'plan_3' }} />
          </div>
        </div>
      </div>
    </div>
  );
}
