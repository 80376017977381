import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import AddonsPopup from './AddonsPopup/AddonsPopup';
import './LearnAboutAddons.css';
const LearnAboutAddons: React.FC<any> = ({ isFree }) => {
  const [popupToggle, setPopupToggle] = useState<boolean>(false);
  return (
    <>
      <div
        id="learn-about-addons"
        className="cta-button-container"
        style={{ ...(isFree ? { opacity: 0, pointerEvents: 'none' } : {}) }}
      >
        <button
          className="plan-btn current"
          onClick={(e) => {
            e.stopPropagation();
            setPopupToggle(true);
          }}
        >
          <FormattedMessage
            id={'billing.learnAboutAddons'}
            defaultMessage={'Learn about Add-on credits'}
          />
        </button>
      </div>
      {popupToggle && <AddonsPopup onClose={setPopupToggle} />}
    </>
  );
};

export default LearnAboutAddons;
