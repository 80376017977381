import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import AddonsPopup from './AddonsPopup/AddonsPopup';

const LearnAboutAddons: React.FC<any> = () => {
  const [popupToggle, setPopupToggle] = useState<boolean>(false);
  return (
    <>
      <div id="learn-about-addons" className="cta-button-container">
        <button
          className="plan-btn-old currentOld"
          style={{ display: 'flex', alignItems: 'center', padding: '12px 5px', fontSize: 15 }}
          onClick={(e) => {
            e.stopPropagation();
            setPopupToggle(true);
          }}
        >
          <FormattedMessage
            id={'billing.learnAboutAddons'}
            defaultMessage={'Learn about Add-on credits'}
          />
        </button>
      </div>
      {popupToggle && <AddonsPopup onClose={setPopupToggle} />}
    </>
  );
};

export default LearnAboutAddons;
