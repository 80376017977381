import React from 'react';
import { FormattedMessage } from 'react-intl';
import { planIncludes } from '../PlanCard/utils';

import CustomToolTip from '../../../../components/CustomToolTip/CustomToolTip';
import './PlanPermissions.css';
import { getPlanPermissions } from './getPlanPermissions';

const PlanPermissions = ({ staticPlan, currentPlan }: any) => {
  const permissions = getPlanPermissions({
    planId: currentPlan.planId,
    isYearly: currentPlan.yearly,
  });
  const getTitleMessageId2 = (planId: any) =>
    //@ts-expect-error
    planIncludes[currentPlan.planId]?.id || planIncludes[currentPlan.planId];

  return (
    <div className="permissions">
      <p className="permissions__title">
        <strong>
          <FormattedMessage
            id={getTitleMessageId2(currentPlan.planId)}
            //@ts-expect-error
            values={{ value: planIncludes[currentPlan.planId]?.value }}
          />
        </strong>
      </p>
      {permissions &&
        permissions.map((item: any, index: any) => (
          <div key={index} className="permission-name">
            <img src="/assets/images/check-outline-icon.svg" />
            {item.tooltip ? (
              <CustomToolTip
                element={item.tooltip}
                style={{ transform: 'translateY(10px)', width: 200 }}
              >
                <span className="permission-name permission-name-txt">{item.name}</span>
              </CustomToolTip>
            ) : (
              <span
                style={{ textDecoration: 'none', cursor: 'default' }}
                className="permission-name permission-name-txt"
              >
                {item.name}
              </span>
            )}
          </div>
        ))}
    </div>
  );
};

export default PlanPermissions;
