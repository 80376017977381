import React from 'react';
import './Switch.css';
type Props = {};

const Switch = ({ leftLabel, rightLabel, checked, onChange, style }: any) => {
  return (
    <div className="custom-switch" style={style}>
      <label htmlFor=""> {leftLabel} </label>
      <div className="switch-btn ">
        <input
          type="checkbox"
          className="switch"
          onChange={(e) => onChange(e.target.checked)}
          checked={checked}
        />
      </div>
      <label htmlFor=""> {rightLabel} </label>
    </div>
  );
};

export default Switch;
