import React from 'react';
import { useIntl } from 'react-intl';
import { getCurrency } from '../../services/utils/tools';

const NumberFormatter = ({
  value,
  user = null, // User object to retrieve currency information
  customer = null, // Customer object to retrieve currency information
  currency = false, // Boolean to enable automatic currency symbol
  decimals = false, // Option to display decimals
  style,
  withoutStyle,
}: any) => {
  const intl = useIntl();

  // Convertir into number
  const numericValue = typeof value === 'number' ? value : parseFloat(value);

  if (isNaN(numericValue)) {
    console.error('NumberFormatter: value must be a valid number');
    return <span style={style}>Invalid number</span>;
  }

  // Retrieve the currency symbol from getCurrency
  const currencySymbol = currency ? getCurrency(user, customer) : '';

  // Determine if decimals should be displayed
  const shouldShowDecimals =
    decimals && !(numericValue % 1 === 0 || numericValue.toFixed(2).endsWith('.00'));

  // Define formatting options for the number, based on locale
  const options = {
    minimumFractionDigits: shouldShowDecimals ? 2 : 0,
    maximumFractionDigits: shouldShowDecimals ? 2 : 0,
  };

  const isNegative = numericValue < 0;

  // Use absolute value to format
  const absoluteValue = Math.abs(numericValue);

  // Format the number according to the user's locale
  const formattedNumber = new Intl.NumberFormat(intl.locale, options).format(absoluteValue);

  // Add "-" before currency symbol if needed
  let output = '';
  if (currencySymbol && intl.locale.startsWith('en')) {
    // Currency symbol is in front
    output = isNegative
      ? `-${currencySymbol}${formattedNumber}`
      : `${currencySymbol}${formattedNumber}`;
  } else {
    // Currency symbol is after the number
    output = isNegative
      ? `-${formattedNumber}${currencySymbol}`
      : `${formattedNumber}${currencySymbol}`;
  }

  // Display the formatted number with the currency symbol
  if (withoutStyle) return output;
  return <span style={style}>{output}</span>;
};

export default NumberFormatter;
