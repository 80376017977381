import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import './PlansHeader.css';
import { Animation, Switch } from '../../../../components';
import { CURRENCY_MAP } from '../../../../constants';

import CustomToolTip from '../../../../components/CustomToolTip/CustomToolTip';
import InputCounter from '../InputCounter/InputCounter';
import NumberOfLicences from './NumberOfLicences/NumberOfLicences';

export function PlansHeader({
  selectPlanDuration,
  subscriptionType,
  plan,
  organizationCredits,
  setNumberOfSeats,
  numberOfSeats,
}: any) {
  return (
    <div id={'plan_header'}>
      <div className="plan_header__title">
        <h1>
          <FormattedMessage id="billing.simpleTransparentPricing" />
        </h1>
        <p>
          <FormattedMessage id="billing.chooseThePerfectPlan" />
        </p>
        <div className="plan_header__switch">
          {organizationCredits.plan.numberOfMonths === 12 ? (
            <CustomToolTip
              element={
                <FormattedMessage id={'billing.customizePlan.youShouldUnsubscribeTooltip'} />
              }
              style={{ transform: 'translateX(-100px)' }}
            >
              <div style={{ pointerEvents: 'none', opacity: 0.4 }}>
                <Switch
                  checked={subscriptionType == 'yearly'}
                  leftLabel={<FormattedMessage id={'billing.billMonthly'} />}
                  rightLabel={
                    <FormattedMessage
                      id={'billing.annual25%Off'}
                      values={{
                        span: (chunk) => <span> {chunk} </span>,
                      }}
                    />
                  }
                  onChange={(checked: boolean) =>
                    selectPlanDuration(checked ? 'yearly' : 'monthly')
                  }
                />
              </div>
            </CustomToolTip>
          ) : (
            <Switch
              checked={subscriptionType == 'yearly'}
              leftLabel={<FormattedMessage id={'billing.billMonthly'} />}
              rightLabel={
                <FormattedMessage
                  id={'billing.annual25%Off'}
                  values={{
                    span: (chunk) => <span> {chunk} </span>,
                  }}
                />
              }
              onChange={(checked: boolean) => selectPlanDuration(checked ? 'yearly' : 'monthly')}
            />
          )}
        </div>
      </div>
      {/* 
      <div className={'plan_section'}>
        <div>1. Select your plan</div>
        <NumberOfLicences
          onChange={setNumberOfSeats}
          currentNumberOfSeats={numberOfSeats}
          minSeats={
            (organizationCredits?.plan?.planId !== '0' &&
              organizationCredits?.plan.numberOfSeats) ||
            1
          }
        />
      </div> */}
    </div>
  );
}
