import { useIntl } from 'react-intl';

const getFeatures = () => {
  const intl = useIntl();
  return [
    {
      category: intl.formatMessage({ id: 'billing.features.planDetails' }),
      items: [
        {
          name: intl.formatMessage({ id: 'billing.features.b2bCredits' }),
          tooltip: intl.formatMessage({ id: 'billing.features.b2bCredits.tooltip' }),
          free: intl.formatMessage({ id: 'billing.features.valPerMonth' }, { value: 15 }),
          starter: intl.formatMessage({ id: 'unlimited' }),
          business: intl.formatMessage({ id: 'unlimited' }),
          enterprise: intl.formatMessage({ id: 'unlimited' }),
        },
        {
          name: intl.formatMessage({ id: 'billing.features.phoneCredits' }),
          tooltip: intl.formatMessage({ id: 'billing.features.phoneCredits.tooltip' }),
          free: intl.formatMessage({ id: 'billing.features.valPerMonth' }, { value: 5 }),
          starter: intl.formatMessage({ id: 'billing.features.valPerYear' }, { value: '1,200' }),
          business: intl.formatMessage({ id: 'billing.features.valPerYear' }, { value: '2,400' }),
          enterprise: intl.formatMessage({ id: 'unlimited' }),
        },
        {
          name: intl.formatMessage({ id: 'billing.features.directEmailCredits' }),
          tooltip: intl.formatMessage({ id: 'billing.features.directEmailCredits.tooltip' }),
          free: intl.formatMessage({ id: 'billing.features.valPerMonth' }, { value: 5 }),
          starter: intl.formatMessage({ id: 'billing.features.valPerYear' }, { value: '60' }),
          business: intl.formatMessage({ id: 'billing.features.valPerYear' }, { value: '2,400' }),
          enterprise: intl.formatMessage({ id: 'unlimited' }),
        },
        {
          name: intl.formatMessage({ id: 'billing.features.exportLimit' }),
          tooltip: intl.formatMessage({ id: 'billing.features.exportLimit.tooltip' }),
          free: '100',
          starter: '3,000',
          business: '12,000',
          enterprise: intl.formatMessage({ id: 'unlimited' }),
        },
        {
          name: intl.formatMessage({ id: 'billing.features.sharedCredits' }),
          tooltip: intl.formatMessage({ id: 'billing.features.sharedCredits.tooltip' }),
          free: false,
          starter: true,
          business: true,
          enterprise: true,
        },
        {
          name: intl.formatMessage({ id: 'billing.features.creditsRollOver' }),
          tooltip: intl.formatMessage({ id: 'billing.features.creditsRollOver.tooltip' }),
          free: false,
          starter: true,
          business: true,
          enterprise: true,
        },
      ],
    },
    {
      category: intl.formatMessage({ id: 'billing.features' }),
      items: [
        {
          name: intl.formatMessage({ id: 'billing.features.kasprLinkedinExtension' }),
          free: true,
          starter: true,
          business: true,
          enterprise: true,
        },
        {
          name: intl.formatMessage({ id: 'billing.features.kasprOnSalesNavigator' }),
          free: false,
          starter: true,
          business: true,
          enterprise: true,
        },
        {
          name: intl.formatMessage({ id: 'billing.features.kasprOnRecruiterLite' }),
          free: false,
          starter: false,
          business: true,
          enterprise: true,
        },
        {
          name: intl.formatMessage({ id: 'billing.features.leadManagement' }),
          tooltip: intl.formatMessage({ id: 'billing.features.leadManagement.tooltip' }),
          free: true,
          starter: true,
          business: true,
          enterprise: true,
        },
        {
          name: intl.formatMessage({ id: 'billing.features.CSVEnrichment' }),
          tooltip: intl.formatMessage({ id: 'billing.features.CSVEnrichment.tooltip' }),
          free: true,
          starter: true,
          business: true,
          enterprise: true,
          expandable: true,
          subItems: [
            {
              name: intl.formatMessage({ id: 'billing.features.launchesPerMonth' }),
              tooltip: intl.formatMessage({ id: 'billing.features.launchesPerMonthCSV.tooltip' }),
              free: '3',
              starter: '3',
              business: '10',
              enterprise: intl.formatMessage({ id: 'unlimited' }),
            },
            {
              name: intl.formatMessage({ id: 'billing.features.rowsPerCSVFile' }),
              tooltip: intl.formatMessage({ id: 'billing.features.rowsPerCSVFile.tooltip' }),
              free: '25',
              starter: '500',
              business: '1,000',
              enterprise: '10,000',
            },
          ],
        },
        {
          name: intl.formatMessage({ id: 'billing.features.enrichmentAutomations' }),
          tooltip: intl.formatMessage({ id: 'billing.features.enrichmentAutomations.tooltip' }),
          free: true,
          starter: true,
          business: true,
          enterprise: true,
          expandable: true,
          subItems: [
            {
              name: intl.formatMessage({ id: 'billing.features.launchesPerMonth' }),
              tooltip: intl.formatMessage({
                id: 'billing.features.launchesPerMonthAuthomation.tooltip',
              }),
              free: '3',
              starter: '3',
              business: '10',
              enterprise: intl.formatMessage({ id: 'unlimited' }),
            },
            {
              name: intl.formatMessage({ id: 'billing.features.leadsPerLaunch' }),
              tooltip: intl.formatMessage({ id: 'billing.features.leadsPerLaunch.tooltip' }),
              free: '25',
              starter: '500',
              business: '1,000',
              enterprise: '10,000',
            },
            {
              name: intl.formatMessage({ id: 'billing.features.enrichFromLinkedin' }),
              tooltip: intl.formatMessage({ id: 'billing.features.enrichFromLinkedin.tooltip' }),
              free: true,
              starter: true,
              business: true,
              enterprise: true,
            },
            {
              name: intl.formatMessage({ id: 'billing.features.enrichFromSalesNav' }),
              tooltip: intl.formatMessage({ id: 'billing.features.enrichFromSalesNav.tooltip' }),
              free: false,
              starter: true,
              business: true,
              enterprise: true,
            },
            {
              name: intl.formatMessage({ id: 'billing.features.enrichFromLinkedinGroup' }),
              tooltip: intl.formatMessage({
                id: 'billing.features.enrichFromLinkedinGroup.tooltip',
              }),
              free: true,
              starter: true,
              business: true,
              enterprise: true,
            },
            {
              name: intl.formatMessage({ id: 'billing.features.enrichFromLinkedinEvents' }),
              tooltip: intl.formatMessage({
                id: 'billing.features.enrichFromLinkedinEvents.tooltip',
              }),
              free: true,
              starter: true,
              business: true,
              enterprise: true,
            },
          ],
        },
        {
          name: intl.formatMessage({ id: 'billing.features.integrations' }),
          tooltip: intl.formatMessage({ id: 'billing.features.integrations2.tooltip' }),
          free: true,
          starter: true,
          business: true,
          enterprise: true,
          expandable: true,
          subItems: [
            { name: 'HubSpot', free: true, starter: true, business: true, enterprise: true },
            { name: 'Salesforce', free: true, starter: true, business: true, enterprise: true },
            { name: 'Pipedrive', free: true, starter: true, business: true, enterprise: true },
            { name: 'Lemlist', free: true, starter: true, business: true, enterprise: true },
            { name: 'Brevo', free: true, starter: true, business: true, enterprise: true },
            { name: 'Zoho', free: true, starter: true, business: true, enterprise: true },
          ],
        },
        {
          name: intl.formatMessage({ id: 'billing.plan.integrationsDialers' }),
          tooltip: intl.formatMessage({ id: 'billing.features.dialers.tooltip' }),
          free: true,
          starter: true,
          business: true,
          enterprise: true,
        },
        {
          name: 'Zapier',
          free: false,
          starter: true,
          business: true,
          enterprise: true,
        },
        {
          name: intl.formatMessage({ id: 'billing.features.appiAccessUponReq' }),
          free: false,
          starter: true,
          business: true,
          enterprise: true,
          expandable: true,
          subItems: [
            {
              name: intl.formatMessage({ id: 'billing.features.requestPerHour' }),
              free: false,
              starter: '20',
              business: '250',
              enterprise: intl.formatMessage({ id: 'unlimited' }),
            },
            {
              name: intl.formatMessage({ id: 'billing.features.requestPerDay' }),
              free: false,
              starter: '60',
              business: '500',
              enterprise: intl.formatMessage({ id: 'unlimited' }),
            },
          ],
        },
        {
          name: intl.formatMessage({ id: 'billing.features.teamCreditsActivity' }),
          free: false,
          starter: true,
          business: true,
          enterprise: true,
        },
        {
          name: intl.formatMessage({ id: 'billing.features.hideDirectEmail' }),
          tooltip: intl.formatMessage({ id: 'billing.features.hideDirectEmail.tooltip' }),
          free: true,
          starter: true,
          business: true,
          enterprise: true,
        },
        {
          name: intl.formatMessage({ id: 'billing.features.chatSupport' }),
          free: true,
          starter: true,
          business: true,
          enterprise: true,
        },
      ],
    },
  ];
};

export default getFeatures;
