import React, { useEffect, useRef, useState } from 'react';
import './ComparePlans.css';
import { checkPermission } from '../../../../services/utils';
import { FormattedMessage } from 'react-intl';
import CustomToolTip from '../../../../components/CustomToolTip/CustomToolTip';
import ComparisonTable from './ComparisonTable/ComparisonTable';

export default function ComparePlans({ flags }: any) {
  return (
    <div className="plans_v2_container">
      <div id={'compare-plans'} style={{ paddingTop: 0 }}>
        <div className={'plan-info-container'}>
          <ComparisonTable flags={flags} />
        </div>
      </div>
    </div>
  );
}
